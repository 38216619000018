<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.legislation"></custom-page-title>

                <ul>
                    <li><a
                        href="http://njt.hu/cgi_bin/njt_doc.cgi?docid=159096" target="_blank">2013. évi
                        V. törvény</a> a Polgári Törvénykönyvről (Ptk.)
                    </li>
                    <li><a href="http://njt.hu/cgi_bin/njt_doc.cgi?docid=101684"
                           target="_blank">2006. évi V. törvény</a> a cégnyilvánosságról, a bírósági
                        cégeljárásról
                        és a végelszámolásról (Ctv.)
                    </li>
                    <li><a href="http://njt.hu/cgi_bin/njt_doc.cgi?docid=49554"
                           target="_blank">2000. évi C. törvény</a> a számvitelről
                    </li>
                    <li><a href="http://njt.hu/cgi_bin/njt_doc.cgi?docid=101415"
                           target="_blank"> 1/2006. (VI. 26.) IRM rendelet</a> a Céginformációs
                        és az Elektronikus
                        Cégeljárásban Közreműködő Szolgálat működéséről, valamint a
                        céginformáció költségtérítéséről
                    </li>
                    <li><a href="http://njt.hu/cgi_bin/njt_doc.cgi?docid=110119"
                           target="_blank">47/2007. (X.20.) IRM rendelet</a> az ingyenes
                        céginformációról
                    </li>
                    <li><a href="http://njt.hu/cgi_bin/njt_doc.cgi?docid=102514"
                           target="_blank">25/2006. (V.18.) IM rendelet</a> az illeték és a
                        közzétételi költségtérítés
                        elektronikus úton történő megfizetéséről a cégeljárásban és
                        más cégügyekben
                    </li>
                    <li><a href="http://njt.hu/cgi_bin/njt_doc.cgi?docid=102481"
                           target="_blank">24/2006. (V.18.) IM rendelet</a> az elektronikus
                        cégbejegyzési eljárás és
                        cégnyilvántartás egyes kérdéseiről
                    </li>
                    <li><a href="http://njt.hu/cgi_bin/njt_doc.cgi?docid=102415"
                           target="_blank">22/2006. (V.18.) IM rendelet</a> a Cégközlönyben megjelenő
                        közlemények közzétételéről és költségtérítéséről
                    </li>
                    <li><a href="http://njt.hu/cgi_bin/njt_doc.cgi?docid=102381"
                           target="_blank">21/2006. (V.18.) IM rendelet </a>a cégbejegyzési eljárás
                        és a
                        cégnyilvántartás egyes kérdéseiről
                    </li>
                </ul>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpLegislation extends Vue {
}
</script>

<style scoped>

</style>